import React from "react"

//Import de composants
import LegalLayout from "../../components/legals/LegalLayout"
import Article from "../../components/legals/ArticleMaker"
import Summary from "../../components/legals/Summary"

//Import de fichiers
import conditionsServices from '../../utils/Legals/cpp.json'

/**
 *
 * @returns Le corps principal de la page
 * @constructor
 */
export default function Cpp({location}){
  return(
    <LegalLayout activeLink={location.pathname}>
      <div className="cpp">
        <div className="cpp__head"><h1>Politiques de confidentialités</h1></div>
        {
          conditionsServices.fr.map((art) => {
            return <Article key={art.ancre} article={art} prefixClass="" />
          })
        }
      </div>
      <Summary tabTitle={Array.from(conditionsServices.fr, (el) => {return [el.ancre, el._id]}) }/>
    </LegalLayout>
  )
}